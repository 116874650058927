import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "src/assets/logo.png";
import { WebNavLinks, MobileNavLinks } from "./naviLinks";
import CompanyProfileMenu from "src/screens/innerScreens/company_profile_menu/companyProfileMenu";
import { logout as logoutAction, selectAuth } from "src/store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import BranchProfileMenu from "src/screens/innerScreens/branch_profile_menu/branchProfileMenu";
import SuperAdminProfileMenu from "src/screens/innerScreens/super_admin_profile_menu/superAdminProfileMenu";
import UserProfileMenu from "src/screens/innerScreens/user_profile_menu/userProfileMenu";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'ion-icon': any;
    }
  }
}

const Navigation = () => {

  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const closeMobilePanel = () => {
    setOpen(false);
  };

  const handleMenuClick = (menuName: string) => {
    setActiveMenu(menuName === activeMenu ? "" : menuName);
  };

  const sessionData = JSON.parse(
    sessionStorage.getItem("MenuList") || "null"
  ) || { Data: [] };

  const handleMainMenuClick = (menuName: string) => {
    setActiveMenu(menuName === activeMenu ? "" : menuName);
  };

  const closeMenu = () => {
    setActiveMenu("");
    closeMobilePanel();
  };

  const HandleProfileMenu = () => {
    if (auth.user?.UserTypeID === 1) {
      return (<SuperAdminProfileMenu />);
    } else if (auth.user?.UserTypeID === 2) {
      return (<CompanyProfileMenu />);
    } else if (auth.user?.UserTypeID === 3) {
      return (<BranchProfileMenu />);
    } else {
      return (<UserProfileMenu />);
    }
  };

  return (
    <nav className="bg-white borderbottom fixnavigation">
      <div className="flex items-center font-medium naviinner">
        <div className="z-50 md:w-auto w-full">
          <div className="mobilelogo"><img src={Logo} alt="logo" className="md:cursor-pointer mainlogo" /></div>
          <div className="text-3xl burgermenu md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>

        <ul className="horimenu md:flex hidden uppercase items-center gap-6 font-[Poppins]">
          {sessionData.Data.map((menuItem: any, index: number) => (
            <li key={index}>
              {menuItem.Url === "#" ? (
                <WebNavLinks menuItem={menuItem}
                  closeMobilePanel={closeMobilePanel}
                  activeMenu={activeMenu}
                />
              ) : (
                <Link to={menuItem.Url} 
                className="py-5 px-3 inline-block menu"
                onClick={closeMobilePanel}
                >
                  {menuItem.Name}
                </Link>
              )}
            </li>
          ))}
        </ul>

        {HandleProfileMenu()}

        <ul
          className={`
            md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
            duration-500 ${open ? "left-0" : "left-[-100%]"}
          `}
        >
          {sessionData.Data.map((menuItem: any, index: number) => (
            <li key={index}>
              {menuItem.Url === "#" ? (
                <MobileNavLinks
                  menuItem={menuItem}
                  activeMenu={activeMenu} // Pass active menu state to NavLinks
                  handleMainMenuClick={handleMainMenuClick} // Pass click handler to NavLinks
                  closeMenu={closeMenu} // Pass closeMenu prop
                />
              ) : (
                <Link to={menuItem.Url} 
                  className="py-3 px-3 inline-block menu"
                  onClick={() => closeMenu()}
                >
                  {menuItem.Name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};



export default Navigation;
