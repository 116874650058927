import React from 'react'
import profileimg from 'src/assets/profile.jpg';
import closebutton from 'src/assets/close-button.png';
import flower from 'src/assets/flower.png';
import addbranch from 'src/assets/plus.png';
import 'src/index.css';
import { LOGOUT } from "src/components/helper/constants";
import { useNavigate } from "react-router";
import { useState, useEffect, useRef } from 'react';
import { MenuItem, Select } from "@mui/material";
import i18n from 'src/components/util/languageResource';

import {  logout as logoutAction, selectAuth } from "src/store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import UseSuperAdminProfileMenu from './useSuperAdminProfileMenu';

interface DropdownItemProps {
  img: string;
  text: string;
  companyLocation: string;
  companyid: string;
  onClick?: () => void;
}

const SuperAdminProfileMenu = () => {
  type branch = {
    CompanyName: string,
    companyLocation: string,
    UserID: string,
    AliseNameUserID: string,
  };
  
  const { selectCompanyList } = UseSuperAdminProfileMenu();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const userInfo = useSelector(selectAuth);
  const [UserAvaterImagePath] = useState(userInfo.user?.ProfileImagePath);
  const [open, setOpen] = useState(false);
  let menuRef = useRef<HTMLDivElement>(null);
  const handlelogout = () => {
    dispatch(logoutAction());
    LOGOUT();
  };
  const handleProfileClick = () => {
    navigate("/Profile");
  };
  const handleCreateBranch = () => {
    //navigate("/RegisterBranch");
  };
  const handalCompanyDashboard = (CompanyMenuBranchListID : string) => {
     sessionStorage.setItem("CompanyMenuBranchUserActive", "true");
     sessionStorage.setItem("CompanyMenuBranchListID", CompanyMenuBranchListID);
    navigate("/SuperAdminCompanyDashboard");
  }
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'hn', label: 'हिंदी' },
    { value: 'mr', label: 'मराठी' },
  ];

  const changeLanguage = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  };

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && i18n.language !== storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <div className="company-profilemenu">
      <div className='company-profilemenu-container' ref={menuRef}>
        <div className='company-profilemenu-trigger' onClick={() => setOpen(!open)}>
        <img src={UserAvaterImagePath != process.env.REACT_APP_API_URL ? UserAvaterImagePath :"/defaultProfile.jpg"} />
        </div>

        <div className={`company-profilemenu-dropdown-menu ${open ? 'active' : 'inactive'}`} >
          <div className='closebutton'>
            <a href='#'><img src={closebutton} alt="Close Button" onClick={() => setOpen(!open)} /></a>
          </div>
          <div className='profileouter'>
            <div className='profileimg'>
            <img src={UserAvaterImagePath != process.env.REACT_APP_API_URL ? UserAvaterImagePath :"/defaultProfile.jpg"} />
            </div>
          </div>
          <div className='profilename'>
            <h3>{auth.user?.FirmName}</h3>
            <p>Company #: <span>{auth.user?.UserID}</span></p>
            <p><a onClick={handleProfileClick} href="" className='prolink'>My Profile</a></p>
            <p><a href="#" onClick={() => handlelogout()} className='signout'>Signout</a></p>
          </div>

          <div className='languagebox'>
            <div><h6>{'Select Language'}</h6></div>
            <div>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={i18n.language}
                label={'Language'}
                onChange={(e) => changeLanguage(e.target.value)}
                sx={{
                  '& fieldset': {
                    border: 'none',
                  },
                  '& .MuiSelect-select': {
                    padding: '0px',
                    marginBottom: '10px',
                  },
                  '& .MuiSelect-icon': {
                    marginTop: '-5px',
                  },
                }}
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className='userlist'><h6>Company List</h6></div>
          <ul className='company-profilemenu-ul'>
            {selectCompanyList.map((company: branch) => (
              <CompanyProDropdownItem img={flower}
                text={company.CompanyName}
                companyLocation={"Pune"}
                //branchid={branch.AliseNameUserID}
                companyid={company.UserID}
                onClick={() =>handalCompanyDashboard(company.UserID)} />
            ))}
          </ul>
          <div className='addbranch'>
            <a onClick={handleCreateBranch} href=''><img src={addbranch} alt="Add Company" /> <span>Company Registration</span></a>
          </div>
        </div>
      </div>
    </div>
  )
}

const CompanyProDropdownItem = (props: DropdownItemProps) => {
  const handleCompanyClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  }
  return (
    <li className='company-profilemenu-dropdownItem'>
      <a href="" onClick={handleCompanyClick}>
        <img src={props.img} alt={props.text} />
        <span>{props.text}<p>{props.companyLocation}</p>
          <p>CompanyID : {props.companyid}</p>
        </span>
      </a>
    </li>
  );
}

export default SuperAdminProfileMenu;