import { Box, Typography } from '@mui/material';
import React from 'react'

const CopyRights = () => {
    return (

        <Box sx={{
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            "@media (max-width: 768px)": {
                zIndex:'-1',
                // bottom: 'inherit',
                bottom: '0',
            },
        }}>
            {/* <Typography sx={{
                background:'#fff',
                color: '#636363',
                fontSize: '14px',
                fontWeight:'300',
                display:'inline-block',
                borderRadius: '12px',
                padding:'2px 20px',
                mb: "5px",
            }}>Copyright @ 2024 all rights reserved.</Typography> */}
            <Box sx={{
                display: 'flex',
                justifyContent:'center',
            }}>
                <Box sx={{
                    background: '#13B705',
                    height: '8px',
                    width: '85%',
                }}></Box>
            </Box>
        </Box>

    )
}

export default CopyRights;