import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import Routes from "src/components/routes/routes";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});
const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <ToastContainer position="bottom-right" newestOnTop />
    </ThemeProvider>
  )
};

export default App;
