import React, { ReactNode } from "react";
import Navbar from "../navbar/navbar";
import { useLocation } from "react-router-dom";
import Footer from "../copyRights/copyRights";

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation();

  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/Sign_up";
  return (
    <div>
      {!isLoginPage && <Navbar />}

      {children}

      {!isLoginPage && <Footer />}
    </div>
  );
};

export default AuthLayout;
