import axios from "axios";
import { SecurityServices } from "./crypto";

import {
  IsValidStatusCode,
  IsValidString,
} from "src/components/helper/validation";
import {
  SERVER_DOWN_ERROR,
  UNABLE_TO_PROCESS_REQUEST_ERROR,
  UNAUTHORIZED_ACCESS_REQUEST_ERROR,
} from "src/components/helper/message";

const getBaseUrl = async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  return BASE_URL;
};

export const isBrowser = (): boolean => {
  return typeof window !== "undefined";
};

export const sessionStorage = (): Storage | void => {
  if (isBrowser()) {
    return window.sessionStorage;
  }
};

type Response = {
  isAuthorized: boolean;
  status: boolean;
  data: string | undefined;
};
type APIResponse = { status: boolean; body: string };

const axiosInstance = axios.create({
  timeout: 25 * 60 * 1000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();

    return config;
  },
  (error) => Promise.reject(error)
);

const getKeyData = () => {
  let UserID = sessionStorage()?.getItem("UserID");
  //let UserID =  sessionStorage()?.getItem("CompanyMenuBranchUserActive") ? sessionStorage()?.getItem("CompanyMenuBranchListID") : sessionStorage()?.getItem("UserID");
  // above code for branch access
  let RefreshToken = sessionStorage()?.getItem("RefreshToken");
  let JsonKeyData = {
    UserID: UserID,
    IMEI: "IMEI",
    Platform: "WEB",
    RefreshToken: RefreshToken,
  };

  let KeyData = JSON.stringify(JsonKeyData);
  let result = SecurityServices.encryptFun(KeyData);

  return result;
};

const GetFormData = (request: string) => {
  var form = new FormData();
  form.append("Request", SecurityServices.encryptFun(request));
  form.append("KeyData", getKeyData());
  return form;
};

const GetKeyData = () => {
  var form = new FormData();

  form.append("KeyData", getKeyData());
  return form;
};

const getAuthorizationHeader = () => {
  return sessionStorage()?.getItem("Token");
};

const getData = (message: string) => {
  return message;
};

const GetRequestData = (request: string) => {
  var form = new FormData();
  form.append("Request", SecurityServices.encryptFun(request));

  return form;
};

const ParseResponse = (
  status: number,
  data: APIResponse,
  response: Response
): Response => {
  if (!IsValidStatusCode(status)) {
    response.data = getData(UNABLE_TO_PROCESS_REQUEST_ERROR);
    return response;
  }

  if (status === 401) {
    response.data = getData(UNAUTHORIZED_ACCESS_REQUEST_ERROR);
    response.isAuthorized = false;
    return response;
  }

  if (status !== 200) {
    response.data = getData(UNABLE_TO_PROCESS_REQUEST_ERROR + status);
    response.isAuthorized = false;
    return response;
  }

  if (!IsValidString(data.body)) {
    response.data = getData(UNABLE_TO_PROCESS_REQUEST_ERROR);
    return response;
  }

  let decryptedBody = SecurityServices.decryptFun(data.body);

  if (!IsValidString(decryptedBody)) {
    response.data = getData(UNABLE_TO_PROCESS_REQUEST_ERROR);
    return response;
  }
  response.isAuthorized = true;
  response.status = data.status;
  response.data = decryptedBody;
  return response;
};

export const logout = async () => {
  sessionStorage()?.setItem("UserID", "0");
  sessionStorage()?.setItem("Token", "");
  sessionStorage()?.setItem("Authorize", "false");
  sessionStorage()?.clear();
  if (isBrowser()) {
    window.location.href = "/home";
  }
};

const ParseErrorResponse = (error: any, returnResponse: Response) => {
  if (error === undefined) {
    logout();
    return;
  }

  if (error.response === undefined) {
    logout();
    return;
  }

  let status = error.response.status;

  if (status === 401) {
    returnResponse.isAuthorized = false;

    returnResponse.data = getData(UNAUTHORIZED_ACCESS_REQUEST_ERROR);
    return returnResponse;
  }

  if (error.message === "Network Error") {
    returnResponse.data = getData(SERVER_DOWN_ERROR);
    return returnResponse;
  }
  returnResponse.isAuthorized = false;
  returnResponse.data = getData(
    "Request failed with : " + error.response.status
  );
  return returnResponse;
};

const GetResponseClass = (): Response => {
  return {
    isAuthorized: false,
    status: false,
    data: getData(UNABLE_TO_PROCESS_REQUEST_ERROR),
  };
};

export const PostAPI = async (request: string, url: string) => {
  let response = GetResponseClass();

  let form = GetFormData(request);
  try {
    const { status, data } = await axiosInstance.post(url, form, {
      headers: {
        Authorization: "Bearer " + getAuthorizationHeader(),
      },
    });

    return ParseResponse(status, data, response);
  } catch (error) {
    return ParseErrorResponse(error, response);
  }
};


export const PostAPIWithKeyData = async (keyData: string, url: string) => {
  let response = GetResponseClass();

  let form = new FormData();
  form.append("KeyData", keyData);

  try {
    const { status, data } = await axiosInstance.post(url, form, {
      headers: {
        Authorization: "Bearer " + getAuthorizationHeader(),
      },
    });

    return ParseResponse(status, data, response);
  } catch (error) {
    return ParseErrorResponse(error, response);
  }
};


export const PostAPIWithRequestAndKeyData = async (
  request: string,
  keyData: string,
  url: string
) => {
  let response = GetResponseClass();

  let form = new FormData();
  form.append("Request", SecurityServices.encryptFun(request));
  form.append("KeyData", keyData);

  try {
    const { status, data } = await axiosInstance.post(url, form, {
      headers: {
        Authorization: "Bearer " + getAuthorizationHeader(),
      },
    });

    return ParseResponse(status, data, response);
  } catch (error) {
    return ParseErrorResponse(error, response);
  }
};

export const PostAPIWithoutRequestAndKeyData = async (url: string) => {
  let response = GetResponseClass();

  try {
    const { status, data } = await axiosInstance.post(url);

    return ParseResponse(status, data, response);
  } catch (error) {
    return ParseErrorResponse(error, response);
  }
};

export const PostAPIWithoutRequest = async (url: string) => {
  let response = GetResponseClass();
  let form = GetKeyData();
  try {
    const { status, data } = await axiosInstance.post(url, form, {
      headers: {
        Authorization: "Bearer " + getAuthorizationHeader(),
      },
    });

    return ParseResponse(status, data, response);
  } catch (error) {
    return ParseErrorResponse(error, response);
  }
};

export const PostAPIWithoutKeydata = async (request: string, url: string) => {
  let response = GetResponseClass();

  let form = GetRequestData(request);

  try {
    const { status, data } = await axiosInstance.post(url, form);

    return ParseResponse(status, data, response);
  } catch (error) {
    return ParseErrorResponse(error, response);
  }
};
