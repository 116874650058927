import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const WebNavLinks = ({
  menuItem,
  closeMobilePanel,
  activeMenu,
}: {
  menuItem: any;
  closeMobilePanel: () => void;
  activeMenu: string;
}) => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  return (
    <>
      <div>
        <div className="px-3 text-left md:cursor-pointer group">
          <h1
            className="py-5 flex justify-between items-center md:pr-0 pr-5 group menu"
            onClick={() => {
              heading !== menuItem.Name ? setHeading(menuItem.Name) : setHeading("");
              setSubHeading("");
            }}
          >
            {menuItem.Name}
            <span className="text-xl md:hidden inline">
              <ion-icon
                name={`${heading === menuItem.Name ? "chevron-up" : "chevron-down"}`}
              ></ion-icon>
            </span>
            <span className="text-xl md:mt-1 md:ml-1  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
              <ion-icon name="chevron-down"></ion-icon>
            </span>
          </h1>
          {/* Render submenu only for web */}
          {menuItem.Children && menuItem.Children.length > 0 && window.innerWidth >= 768 && (
            <div>
              <div className={`absolute ${activeMenu === menuItem.Name ? "block" : "hidden"} group-hover:md:block hover:md:block`}>
                <div className="py-3">
                  <div className="w-4 h-4 left-4 absolute mt-1 bg-black rotate-45"></div>
                </div>                
                <div className="bg-black p-6 grid grid-cols-1 gap-7 boxrounded">
                  {menuItem.Children.map((child: any) => (
                    <li className="text-sm text-white" key={child.MenuID}>
                      <Link
                        to={child.Url}
                        className="hover:text-primary submenu"
                        onClick={() => {
                          closeMobilePanel(); // Close mobile panel
                       
                        }}
                      >
                        {child.Name}
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};




export const MobileNavLinks = ({
  menuItem,
  activeMenu,
  handleMainMenuClick,
  closeMenu,
}: {
  menuItem: any;
  activeMenu: string;
  handleMainMenuClick: (menuItemName: string) => void;
  closeMenu: () => void; // Prop to close both submenu and menu
}) => {
  const [heading, setHeading] = useState("");

  useEffect(() => {
    if (activeMenu !== menuItem.Name) {
      setHeading("");
    }
  }, [activeMenu, menuItem.Name]);

  const handleMenuClick = (menuName: string) => {
    if (heading === menuName) {
      setHeading("");
      closeMenu();
    } else {
      setHeading(menuName);
      handleMainMenuClick(menuName);
    }
  };

  return (
    <div>
      <div className="px-3 text-left md:cursor-pointer group">
        <h1
          className="py-5 flex justify-between items-center md:pr-0 pr-5 group menu"
          onClick={() => handleMenuClick(menuItem.Name)}
        >
          {menuItem.Name}
          <span className="text-xl md:hidden inline">
            <ion-icon
              name={`${heading === menuItem.Name ? "chevron-up" : "chevron-down"}`}
            ></ion-icon>
          </span>
          <span className="text-xl md:mt-1 md:ml-1 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
            <ion-icon name="chevron-down"></ion-icon>
          </span>
        </h1>
        {/* Render submenu only for web */}
        {menuItem.Children && menuItem.Children.length > 0 && window.innerWidth >= 768 && (
          <div>
            <div className={`absolute ${activeMenu === menuItem.Name ? "block" : "hidden"} group-hover:md:block hover:md:block`}>
              <div className="py-3">
                <div className="w-4 h-4 left-4 absolute mt-1 bg-black rotate-45"></div>
              </div>
              <div className="bg-black p-6 grid grid-cols-1 gap-7 boxrounded">
                {menuItem.Children.map((child: any) => (
                  <li className="text-sm text-white" key={child.MenuID}>
                    <Link
                      to={child.Url}
                      className="hover:text-primary submenu"
                      onClick={() => closeMenu()}
                    >
                      {child.Name}
                    </Link>
                  </li>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Mobile menus */}
      <div className={`${activeMenu === menuItem.Name ? "md:hidden" : "hidden"}`}>
        {/* Render submenu only for mobile */}
        {menuItem.Children && menuItem.Children.length > 0 && window.innerWidth < 768 && (
          <ul>
            {menuItem.Children.map((child: any) => (
              <li key={child.MenuID} className="py-2 pl-8">
                <Link to={child.Url} className="hover:text-primary submenu"
                  onClick={() => closeMenu()}
                >
                  {child.Name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

