// store.ts
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import authReducer from './authSlice';
import menu_reducer from "src/redux/menu/menuSlice";
import user_reducer from "src/redux/user/userSlice";
import sms_reducer from "src/redux/sms/smsSlice";
const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  auth: authReducer,  
  user: user_reducer,
  menu: menu_reducer,
  sms_setting: sms_reducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({ 
  reducer: persistedReducer,
}); 
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
