import RegisterAdvisorScreen from "src/screens/innerScreens/register_advisor/registerAdvisorScreen";

export const resources = {
  en: {
    public: {
      Home: "Home",
      About: "About",
      Schemes: "Schemes",
      Categories: "Categories",
      "Sign in": "Sign in",
    },
    redirectUserReportScreen: {
      "Redirect Users Report": "Redirect Users Report",
      "Selection Criteria": "Selection Criteria",
      UserName: "User Name",
      Link: "Link",
      Search: "Search",
      FromDate: "From Date",
      ToDate: "To Date",
      Date: "Date",
      Count: "Count",
      Submit: "Submit",
    },

    createBranchScreen: {
      "Create Branch": "Create Branch",
    },

    ProfileGeneralScreen: {
      Notifications: "Notifications",
    },
    ProfileScreen: {
      "Profile/Setting": "Profile/Setting",
      Account: "Account",
    },

    BranchProfileScreen: {
      "My Profile": "My Profile",
      "Branch Information": "Branch Information",
    },
    
    CompanyUserRegistrationScreen: {},

    InvoiceSettingScreen: {},

    ProfileMyProfileScreen: {},

    SowingPlanScreen: {},

    RegisterGeneralManagerScreen: {
      "Register General Manager": "Register General Manager",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Mobile Number": "Mobile Number",
      "General Manager List": "General Manager List",
      Register: "Register",
      Update: "Update",
      "Delete Confirmation":"Delete Confirmation",
      "Are you sure to delete this General Manager?":"Are you sure to delete this General Manager?",
      "Yes":"Yes",
      "No":"No",
    },

    RegisterAdvisorScreen:{
      "Register Advisor":"Register Advisor",
      "Select General Manager":"Select General Manager",
      "First Name":"First Name",
      "Last Name":"Last Name",
      "Mobile Number":"Mobile Number",
      "Advisor List":"Advisor List",
      Register: "Register",
      Update: "Update",
      "Delete Confirmation":"Delete Confirmation",
      "Are you sure to delete this Advisor?":"Are you sure to delete this Advisor?",
      "Yes":"Yes",
      "No":"No",
    },

    RegisterBranchScreen: {
      "Branch Registration": "Branch Registration",
      "Branch Information": "Branch Information",
      "Select General Manager": "Select General Manager",
      "Select Advisor": "Select Advisor",      
      "Branch Name": "Branch Name",
      "Phone No.": "Phone No.",
      Register: "Register",
      Update:"Update",
      "Branch List":"Branch List",
      "Delete Confirmation":"Delete Confirmation",
      "Are you sure to delete this Branch?":"Are you sure to delete this Branch?",
      "Yes":"Yes",
      "No":"No",
    },

    RegisterPlantHeadScreen: {},

    RegisterSupervisorScreen: {},

    RegisterVendorScreen: {},

    RegisterSupplierScreen: {},

    RegisterEmployeeScreen: {},

    RegisterChannelPartnerScreen: {},

    ResponsiveDialog: {},

    CompanyBranchDashboardScreen: {},

  },


  mr: {
    public: {
      Home: "मुखपृष्ठ",
      About: "प्रकल्पाबद्दल",
      Schemes: "योजना",
      Categories: "श्रेणी",
      "Sign in": "साइन इन",
    },
    redirectUserReportScreen: {
      "Redirect Users Report": "पुनर्निर्देशित वापरकर्ते अहवाल",
      "Selection Criteria": "निवड निकष",
      UserName: "वापरकर्ता नाव",
      Link: "लिंक ",
      Search: "शोधा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Date: "तारीख",
      Count: "कॉउंट ",
      Submit: "प्रस्तुत करणे",
    },

    createBranchScreen: {
      "Create Branch": "शाखा तयार करा",
    },

    ProfileGeneralScreen: {
      Notifications: "अधिसूचना",
    },

    ProfileScreen: {
      "Profile/Setting": "प्रोफाइल/सेटिंग",
      Account: "खाते",
    },

    BranchProfileScreen: {
      "My Profile": "माझे प्रोफाइल",
      "Branch Information": "शाखेची माहिती",
    },   

    CompanyUserRegistrationScreen: {},

    InvoiceSettingScreen: {},

    ProfileMyProfileScreen: {},

    SowingPlanScreen: {},

    RegisterGeneralManagerScreen: {
      "Register General Manager": "व्यवस्थापक नोंदणी",
      "First Name": "पहिले नाव",
      "Last Name": "आडनाव",
      "Mobile Number": "मोबाईल नंबर",
      "General Manager List": "व्यवस्थापकांची यादी",
      Register: "नोंदणी करा",
      Update: "दुरुस्त करा",
      "Delete Confirmation":"Delete सूचना",
      "Are you sure to delete this General Manager?":"आपल्याला हा व्यवस्थापक Confirm Delete करायचा आहे का ?",
      "Yes":"हो ",
      "No":"नाही ",
    },

    RegisterAdvisorScreen:{
      "Register Advisor":"सल्लागार नोंदणी",
      "Select General Manager":"व्यवस्थापक निवडा",
      "First Name": "पहिले नाव",
      "Last Name": "आडनाव",
      "Mobile Number": "मोबाईल नंबर",
      "Advisor List":"सल्लागार यादी",
      Register: "नोंदणी करा",
      Update: "दुरुस्त करा",
      "Delete Confirmation":"Delete सूचना",
      "Are you sure to delete this Advisor?":"आपल्याला हा सल्लागार Confirm Delete करायचा आहे का ?",
      "Yes":"हो ",
      "No":"नाही ",
    },

    RegisterBranchScreen: {
      "Branch Registration": "शाखा नोंदणी",
      "Branch Information": "शाखेची माहिती",
      "Select General Manager": "जनरल मॅनेजर निवडा",
      "Select Advisor": "सल्लागार निवडा",
      "Branch Name": "शाखेचे नाव",
      "Phone No.": "दूरध्वनी क्रमांक",
      Register: "नोंदणी करा",
      Update: "दुरुस्त करा",
      "Branch List":"शाखा यादी",
      "Delete Confirmation":"Delete सूचना",
      "Are you sure to delete this Branch?":"आपल्याला ही शाखा Confirm Delete करायची आहे का ?",
      "Yes":"हो ",
      "No":"नाही ",
    },

    RegisterPlantHeadScreen: {},

    RegisterSupervisorScreen: {},

    RegisterVendorScreen: {},

    RegisterSupplierScreen: {},

    RegisterEmployeeScreen: {},

    RegisterChannelPartnerScreen: {},

    ResponsiveDialog: {},

    CompanyBranchDashboardScreen: {},

  },


  hn: {
    ProfileScreen: {
      "Profile/Setting": "प्रोफाइल/सेटिंग",
      Account: "खाता",
    },

    BranchProfileScreen: {
      "My Profile": "मेरी प्रोफाइल",
      "Branch Information": "शाखा सूचना",
    },    

    CompanyUserRegistrationScreen: {},

    InvoiceSettingScreen: {},

    ProfileMyProfileScreen: {},

    SowingPlanScreen: {},

    RegisterGeneralManagerScreen: {
      "Register General Manager": "प्रबंधक पंजीकरण",
      "First Name": "पहला नाम",
      "Last Name": "अंतिम नाम",
      "Mobile Number": "मोबाइल नंबर",
      "General Manager List": "प्रबंधक सूची",
      Register: "पंजीकरण करें",
      Update: "अद्यतन करें",
      "Delete Confirmation":"Delete पुष्टि",
      "Are you sure to delete this General Manager?":"क्या आप Confirm इस प्रबंधक को Delete करना चाहते हैं?",
      "Yes":"हाँ",
      "No":"नहीं",
    },

    RegisterAdvisorScreen: {
      "Register Advisor": "सलाहकार पंजीकरण",
      "Select General Manager": "प्रबंधक चुनें",
      "First Name": "पहला नाम",
      "Last Name": "अंतिम नाम",
      "Mobile Number": "मोबाइल नंबर",
      "Advisor List": "सलाहकार सूची",
      Register: "पंजीकरण करें",
      Update: "अद्यतन करें",
      "Delete Confirmation":"Delete पुष्टि",
      "Are you sure to delete this Advisor?":"क्या आप Confirm इस सलाहकार को Delete करना चाहते हैं?",
      "Yes":"हाँ",
      "No":"नहीं",
    },

    RegisterBranchScreen: {
      "Branch Registration": "शाखा पंजीकरण",
      "Branch Information": "शाखा सूचना",
      "Select General Manager": "महाप्रबंधक का चयन करें",
      "Select Advisor": "सलाहकार का चयन करें",
      "Branch Name": "शाखा नाम",
      "Phone No.": "फोन नंबर",
      "Branch List":"शाखा सूची",
      Register: "पंजीकरण",
      Update: "अद्यतन करें",
      "Delete Confirmation":"Delete पुष्टि",
      "Are you sure to delete this Branch?":"क्या आप Confirm इस शाखा को Delete करना चाहते हैं?",
      "Yes":"हाँ",
      "No":"नहीं",
    }, 

    RegisterPlantHeadScreen: {},

    RegisterSupervisorScreen: {},

    RegisterVendorScreen: {},

    RegisterSupplierScreen: {},

    RegisterEmployeeScreen: {},

    RegisterChannelPartnerScreen: {},

    ResponsiveDialog: {},

    CompanyBranchDashboardScreen: {},

  },
};



