import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/components/navbar/authInnerNavbar";
import { LOGOUT } from "../helper/constants";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "src/store/authSlice";

const CompanyDashborad = lazy(
  () => import("src/screens/innerScreens/company_dashboard/companyDashboardScreen")
);

const SuperAdminDashboard = lazy(
  () => import("src/screens/innerScreens/super_admin_dashboard/superAdminDashboardScreen")
);

const BranchDashborad = lazy(
  () => import("src/screens/innerScreens/branch_dashboard/branchDashboardScreen")
);


const KeySettingScreen = lazy(
  () => import("src/screens/innerScreens/key_setting/keySettingScreen")
);

const MessageSettingScreen = lazy(
  () => import("src/screens/innerScreens/message_setting/messageSettingScreen")
);

const ChangePasswordScreen = lazy(
  () => import("src/screens/innerScreens/profile_change_password/profileChangePasswordScreen")
);

const SmsSettingScreen = lazy(
  () => import("src/screens/innerScreens/sms_setting/smsSettingScreen")
);

const PGSettingScreen = lazy(
  () => import("src/screens/innerScreens/profile_payment_gateway_setting/profilePaymentGatewaySettingScreen")
);

const ProfileScreen = lazy(
  () => import("src/screens/innerScreens/profile/profileScreen")
);

const CreateBranchScreen = lazy(
  () => import("src/screens/innerScreens/create_branch/createBranchScreen")
);

const RegisterBranchScreen = lazy(
  () => import("src/screens/innerScreens/register_branch/registerBranchScreen")
);

const ProfileGeneralScreen = lazy(
  () => import("src/screens/innerScreens/profile_general/profileGeneralScreen")
);

const ProfileMyProfileScreen = lazy(
  () => import("src/screens/innerScreens/profile_my_profile/profileMyProfileScreen")
);

const ProfileEditMyProfileScreen = lazy(
  () => import("src/screens/innerScreens/profile_my_profile/profileEditMyProfileScreen")
);

const ProfileUpdatesScreen = lazy(
  () => import("src/screens/innerScreens/profile_updates/profileUpdatesScreen")
);

const ProfileSecurityScreen = lazy(
  () => import("src/screens/innerScreens/profile_security/profileSecurityScreen")
);

const InvoiceSettingScreen = lazy(
  () => import("src/screens/innerScreens/invoice_setting/invoiceSettingScreen")
);

const ProfileBillingScreen = lazy(
  () => import("src/screens/innerScreens/profile_billing/profileBillingScreen")
);

const BranchProfileScreen = lazy(
  () => import("src/screens/innerScreens/branch_profile/branchProfileScreen")
);

const CompanyUserRegistration = lazy(
  () => import("src/screens/innerScreens/company_user_registration/companyUserRegistrationScreen")
);

const SowingPlanScreen = lazy(
  () => import("src/screens/innerScreens/sowing_plan/sowingPlanScreen")
);

const RegisterGeneralManagerScreen = lazy(
  () => import("src/screens/innerScreens/register_general_manager/registerGeneralManagerScreen")
);

const RegisterAdvisorScreen = lazy(
  () => import("src/screens/innerScreens/register_advisor/registerAdvisorScreen")
);

const RegisterPlantHeadScreen = lazy(
  () => import("src/screens/innerScreens/register_planthead/registerPlantHeadScreen")
);

const RegisterSupervisorScreen = lazy(
  () => import("src/screens/innerScreens/register_supervisor/registerSupervisorScreen")
);

const RegisterVendorScreen = lazy(
  () => import("src/screens/innerScreens/register_vendor/registerVendorScreen")
);

const RegisterSupplierScreen = lazy(
  () => import("src/screens/innerScreens/register_supplier/registerSupplierScreen")
);

const RegisterEmployeeScreen = lazy(
  () => import("src/screens/innerScreens/register_employee/registerEmployeeScreen")
);

const RegisterChannelPartnerScreen = lazy(
  () => import("src/screens/innerScreens/register_channel_partner/registerChannelPartnerScreen")
);

const SentSMSReportScreen = lazy(
  () => import("src/screens/innerScreens/report/sent_sms_report/sentSMSReportScreen")
);

const CompanyBranchDashboardScreen = lazy(
  () => import("src/screens/innerScreens/company_branch_dashboard/companyBranchDashboardScreen")
);

const SuperAdminCompanyDashboardScreen = lazy(
  () => import("src/screens/innerScreens/super_admin_company_dashboard/superAdminCompanyDashboardScreen")
);

const RegisterSowingUnitScreen = lazy(
  () => import("src/screens/innerScreens/register_sowing_unit/registerSowingUnitScreen")
);

const RegisterSowingBedScreen = lazy(
  () => import("src/screens/innerScreens/register_sowing_bed/registerSowingBedScreen")
);

const RegisterMaterialTypeScreen = lazy(
  () => import("src/screens/innerScreens/register_material_type/registerMaterialTypeScreen")
);

const SeedsInventoryScreen = lazy(
  () => import("src/screens/innerScreens/seeds_inventory/seedsInventoryScreen")
);

const RegisterUnitScreen = lazy(
  () => import("src/screens/innerScreens/register_unit/registerUnitScreen")
);



const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutAction());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/CompanyDashboard" element={<CompanyDashborad />} />
          <Route path="/SuperAdminDashboard" element={<SuperAdminDashboard />} />          
          <Route path="/BranchDashboard" element={<BranchDashborad />} />
          <Route path="/Logout" element={<LogoutComponent onLogout={handleLogout} />} />
          <Route path="/Profile" element={<ProfileScreen />} />
          <Route path="/KeySetting" element={<KeySettingScreen />} />
          <Route path="/MessageSetting" element={<MessageSettingScreen />} />
          <Route path="/SmsSetting" element={<SmsSettingScreen />} />
          <Route path="/PGSettingScreen" element={<PGSettingScreen />} />          
          <Route path="/CreateBranch" element={<CreateBranchScreen />} />
          <Route path="/RegisterBranch" element={<RegisterBranchScreen />} />
          <Route path="/ProfileGeneral" element={<ProfileGeneralScreen />} />
          <Route path="/ChangePassword" element={<ChangePasswordScreen />} />
          <Route path="/MyProfile" element={<ProfileMyProfileScreen IsUserProfileUploaded={false} />} />
          <Route path="/ProfileUpdates" element={<ProfileUpdatesScreen />} />
          <Route path="/ProfileSecurity" element={<ProfileSecurityScreen />} />
          <Route path="/InvoiceSetting" element={<InvoiceSettingScreen />} />
          <Route path="/ProfileBilling" element={<ProfileBillingScreen />} />
          <Route path="/BranchProfile" element={<BranchProfileScreen />} />
          <Route path="/CompanyUserRegistration" element={<CompanyUserRegistration />} />
          <Route path="/EditMyProfile" element={<ProfileEditMyProfileScreen />} />
          <Route path="/RegisterGeneralManager" element={<RegisterGeneralManagerScreen />} />
          <Route path="/RegisterAdvisor" element={<RegisterAdvisorScreen />} />
          <Route path="/RegisterPlantHead" element={<RegisterPlantHeadScreen />} />
          <Route path="/RegisterSupervisor" element={<RegisterSupervisorScreen />} />
          <Route path="/RegisterVendor" element={<RegisterVendorScreen />} />
          <Route path="/RegisterSupplier" element={<RegisterSupplierScreen />} />
          <Route path="/RegisterEmployee" element={<RegisterEmployeeScreen />} />
          <Route path="/RegisterChannelPartner" element={<RegisterChannelPartnerScreen />} />

          <Route path="/SentSMSReport" element={<SentSMSReportScreen />} />
          <Route path="/CompanyBranchDashboard" element={<CompanyBranchDashboardScreen IsBranchDashboardUploaded={false} />} />

          <Route path="/SuperAdminCompanyDashboard" element={<SuperAdminCompanyDashboardScreen IsCompanyDashboardUploaded={false} />} />
                   
          
          <Route path="/SowingPlan" element={<SowingPlanScreen />} />
          <Route path="/RegisterSowingUnit" element={<RegisterSowingUnitScreen />} />
          <Route path="/RegisterSowingBed" element={<RegisterSowingBedScreen />} />
          <Route path="/RegisterMaterialType" element={<RegisterMaterialTypeScreen />} />
          <Route path="/SeedsInventory" element={<SeedsInventoryScreen />} />
          <Route path="/RegisterUnit" element={<RegisterUnitScreen />} />
          
          
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
