import React, { useEffect, useState } from 'react'
import {
  SelectCompany as SELECT_COMPANY,
  } from "src/components/api/register_company/registerCompany";
  import { LOGOUT } from 'src/components/helper/constants';
import { ToastError } from 'src/components/uiElements/toast';

const UseSuperAdminProfileMenu = () => {
    const [selectCompanyList, SetSelectCompanyList] = useState([]);

    useEffect(() => {
        SelectCompanyList();
      }, []);
    const SelectCompanyList = async () => {
        SetSelectCompanyList([]);
        const response = await SELECT_COMPANY();
        if (!response?.isAuthorized) LOGOUT();
        if (!response?.status) {
          let failResult = JSON.parse(response?.data!);
          ToastError(failResult.Message);
          return;
        }
        var data = JSON.parse(response?.data!);
        SetSelectCompanyList(data);
      };
  return {
    selectCompanyList,
  }
};

export default UseSuperAdminProfileMenu;