import React from 'react'
import user from 'src/assets/user.png';
import settings from 'src/assets/settings.png';
import help from 'src/assets/question.png';
import logout from 'src/assets/log-out.png';
import closebutton from 'src/assets/close-button.png';
import profileimg from 'src/assets/profile.jpg';
import 'src/index.css';
import { useNavigate } from "react-router";
import i18n from 'src/components/util/languageResource';
import { useState, useEffect, useRef } from 'react';
import { MenuItem, Select } from '@mui/material';
import UseUserProfileMenu from './useUserProfileMenu';
import { LOGOUT } from 'src/components/helper/constants';
import {  logout as logoutAction, selectAuth } from "src/store/authSlice";
import { useDispatch, useSelector } from "react-redux";


interface DropdownItemProps {
  img: string;
  text: string;
  onClick?: () => void;
}

const BranchProfileMenu = () => {

  const {

  } = UseUserProfileMenu();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectAuth);
  const [UserAvaterImagePath] = useState(userInfo.user?.ProfileImagePath);
  const auth = useSelector(selectAuth);
  const handlelogout = () => {
    dispatch(logoutAction());
    LOGOUT();
  };

  const [open, setOpen] = useState(false);
  let menuRef = useRef<HTMLDivElement>(null);

  const handleBranchProfileClick = () => {
    navigate("/Profile");
  }

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'hn', label: 'हिंदी' },
    { value: 'mr', label: 'मराठी' },
    // Add more languages as needed
  ];

  const changeLanguage = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage); // Store selected language in local storage
  };

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setOpen(false);        
      }
    };
    
    

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    // Check if a language is stored in local storage, if so, initialize i18n with that language
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && i18n.language !== storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <div className="profilemenu">
      <div className='profilemenu-container' ref={menuRef}>
        <div className='profilemenu-trigger' onClick={() => setOpen(!open)}>
        <img src={UserAvaterImagePath != process.env.REACT_APP_API_URL ? UserAvaterImagePath :"/defaultProfile.jpg"} />
        </div>

        <div className={`profilemenu-dropdown-menu ${open ? 'active' : 'inactive'}`} >
          <div className='closebutton'>
            <a href='#'><img src={closebutton} alt="Close Button" onClick={() => setOpen(!open)} /></a>
          </div>
          <div className='profileouter'>
            <div className='profileimg'>
            <img src={UserAvaterImagePath != process.env.REACT_APP_API_URL ? UserAvaterImagePath :"/defaultProfile.jpg"} />
            </div>
          </div>
          <div className='branchprofilename'>
          <h3>{auth.user?.FirmName}</h3>
          <p><span>{auth.user?.UserRole}</span></p>
          <h6> # : <span>{auth.user?.UserID}</span></h6>
          </div>
          

          <div className='languagebox'>
            <div><h6>{'Select Language'}</h6></div>
            <div>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={i18n.language}
                label={'Language'}
                onChange={(e) => changeLanguage(e.target.value)}
                sx={{
                  '& fieldset': {
                    border: 'none', // remove border from the fieldset
                  },
                  '& .MuiSelect-select': {
                    padding: '0px',
                    marginBottom: '10px',
                  },
                  '& .MuiSelect-icon': {
                    marginTop: '-5px', // adjust the top position of the SVG icon
                  },
                }}
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <ul className='profilemenu-ul'>
            <ProfileDropdownItem img={user} text={"My Profile"} onClick={handleBranchProfileClick} />
            <ProfileDropdownItem img={settings} text={"Settings"} />
            <ProfileDropdownItem img={help} text={"Help"} />
            <ProfileDropdownItem img={logout} text={"Logout"} onClick={() => handlelogout()} />
          </ul>
        </div>
      </div>
    </div>
  )
}

const ProfileDropdownItem = (props: DropdownItemProps) => {
  const handleBranchProfileClick = () => {
    if (props.onClick) {
      props.onClick(); // Call the onClick handler if it exists
    }
  }
  return (
    <li className='profilemenu-dropdownItem'>
      <a href=""
        onClick={handleBranchProfileClick}
        style={{
          fontWeight: '400',
        }}>
        <img src={props.img} alt={props.text} />
        {props.text}
      </a>
    </li>
  );
}

export default BranchProfileMenu;