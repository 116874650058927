import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "src/components/navigation/publicNavbar";

const SignIn = lazy(
  () => import("src/screens/outerScreens/signin/signInScreen")
);
const HomeScreen = lazy(() => import("src/screens/outerScreens/home/home"));

const PricingScreen = lazy(() => import("src/screens/outerScreens/pricing/pricingScreen"));

const CartScreen = lazy(() => import("src/screens/outerScreens/cart/cartScreen"));

const CheckoutScreen = lazy(() => import("src/screens/outerScreens/checkOut/checkOutScreen"));
const EmptyCartScreen = lazy(() => import("src/screens/outerScreens/emptyCart/emptyCartScreen"));

const AuthRoute: React.FC = () => {
  return (
    <AuthLayout>
      <Suspense>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/pricing" element={<PricingScreen />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path="/emptycart" element={<EmptyCartScreen />} />          
          <Route path="/" element={<HomeScreen />} />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
