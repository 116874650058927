import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRoute from './authroute';
import MainRoute from './mainroute';

const Routes: React.FC = () => {
  const isAuthenticated = sessionStorage.getItem('Token');
  return (
    <>
      {isAuthenticated ? <MainRoute /> : <AuthRoute />}
    </>
  );
};

export default Routes;
