import {
    PostAPI,
    PostAPIWithoutRequest,
    PostAPIWithoutRequestAndKeyData,
  } from "src/components/api/helper/apiHelper";

  export const RegisterBranch = async (request: string) => {
    return PostAPI(request, "RegisterBranch/RegisterBranch");
  };

  export const SelectGMDropdown = async () =>{
    return PostAPIWithoutRequest("User/SelectOnlyGeneralManagerForDropdown");
  };

  export const SelectAdvisorDropdown = async () =>{
    return PostAPIWithoutRequest("User/SelectOnlyAdvisorForDropdown");
  };

  export const SelectBranch = async () =>{
    return PostAPIWithoutRequest("RegisterBranch/SelectBranch");
  };

  export const Delete_Branch = async (request: string) => {
    return PostAPI(request, "RegisterBranch/Delete_Branch");
  };

  export const Update_Branch = async (request: string) => {
    return PostAPI(request, "RegisterBranch/Update_Branch");
  };

  export const SelectByID_Branch = async (request: string) => {
    return PostAPI(request, "RegisterBranch/SelectByID_Branch");
  };