import {
    PostAPI,
    PostAPIWithoutRequest,
    PostAPIWithoutRequestAndKeyData,
  } from "src/components/api/helper/apiHelper";

  export const SelectCompany = async () =>{
    return PostAPIWithoutRequest("RegisterCompany/SelectCompany");
  };

  export const Delete_Company = async (request: string) => {
    return PostAPI(request, "RegisterCompany/Delete_Company");
  };

  export const Update_Company = async (request: string) => {
    return PostAPI(request, "RegisterCompany/Update_Company");
  };

  export const SelectByID_Company = async (request: string) => {
    return PostAPI(request, "RegisterCompany/SelectByID_Company");
  };