import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Grid,
  Box,
  Drawer,
  List,
  styled,
  BadgeProps,
  Badge,
  Link,
  MenuItem,
  Menu,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Navbar: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [currentPath, setCurrentPath] = useState<string>("");

  useEffect(() => {
    setCurrentPath(window.location.pathname);

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check if the page has scrolled beyond a certain threshold
    if (window.scrollY > 34) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNestedMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (nestedAnchorEl) {
      setNestedAnchorEl(null);
    } else {
      setNestedAnchorEl(event.currentTarget);
    }
  };

  const handleNestedMenuClose = () => {
    setNestedAnchorEl(null);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  };

  const handleBack = () => {
    toggleMobileDrawer();
  };
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 20,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        zIndex: "9999",
        background: 'transparent',
        pt: '40px',
        pb: '25px',
        position: isSticky ? "fixed" : "relative", // Updated position based on isSticky
        top: isSticky ? "24px" : "auto", // Added top margin when sticky
        "@media (max-width: 899px)": {
          background: '#fff',
          top: '0px',
          paddingBottom: isSticky ? "60px" : "30px",
        },
      }}
    >
      <AppBar
        position={isSticky ? "fixed" : "relative"}
        sx={{
          top: isSticky ? "24px" : "57px",
          padding: '2px',
          zIndex: 9999,
          bgcolor: isSticky ? "#fff" : "#fff",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 14px 20px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px", // Keeping the same box shadow
          borderRadius: "120px", // Fixed border radius
          width: "auto",
          marginLeft: 'auto',
          marginRight: 'auto',
          right: 'auto',
          "@media (max-width: 1311px)": {
            borderRadius: "30px",
            width: "80%",
          },
          transition: "background-color 0.3s, transform 0.3s", // Smooth transition for background color change and transform
          transform: isSticky ? "translateY(0)" : "translateY(-100%)", // Slide down animation when sticky
        }}
      >
        <Toolbar style={{ minHeight: '50px', paddingRight: '8px', }}>
          <Grid sx={{ mr: "auto" }}>
            <Link href={"/"}>
              <img
                width="72px"
                src="./logo.png"
                alt="Logo"
                style={{ marginRight: '20px' }}
              />
            </Link>
          </Grid>

          <Grid sx={{ display: { xs: "none", md: "flex" } }}>
            <nav>
              <Button
                href="/"
                sx={{
                  color: "#000",
                  fontWeight: '300',
                  fontSize: "16px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  py: '2px',
                  px: "16px",
                  mr: "6px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                Home
              </Button>

              <Button
                sx={{
                  color: "#81859f",
                  fontSize: "16px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  py: '2px',
                  px: "16px",
                  mr: "6px",
                  fontWeight: "300",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
                aria-controls="products-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                endIcon={<ArrowDropDownIcon />}
              >
                Features
              </Button>

              <Menu
                id="products-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "products-menu",
                }}
                PaperProps={{ style: { borderRadius: '14px', top: '92px' } }}
                sx={{ zIndex: '999999' }}

              >
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontWeight: "300",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Link
                    href=""
                    style={{ color: "#000", textDecoration: "none", display: 'inherit' }}
                  >
                    <img src="/icons/user_icon.png" style={{ height: '18px', marginRight: '6px' }} /> Features 1
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontWeight: "300",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Link
                    href=""
                    style={{ color: "#000", textDecoration: "none", display: 'inherit' }}
                  >
                    <img src="/icons/user_icon.png" style={{ height: '18px', marginRight: '6px' }} /> Features 2
                  </Link>
                </MenuItem>

              </Menu>

              <Button
                href="/pricing"
                sx={{
                  color: "#81859f",
                  fontSize: "16px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  py: '2px',
                  px: "16px",
                  mr: "6px",
                  fontWeight: "300",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                Pricing
              </Button>
            </nav>
          </Grid>

          <Grid sx={{ ml: "auto" }}>
            <Box className="productcountout">
              <Box className="productcount">
                <Typography sx={{ fontSize: '14px' }}>0</Typography>
              </Box>
              <Button
              href="/emptyCart"
               sx={{ borderRadius: "24px", mr: '6px' }}>
                <img src="/cart.svg" style={{ width: '26px' }} />
              </Button>

            </Box>

            <Button
              href="/SignIn"
              size="large"
              sx={{
                color: "#fff",
                fontSize: "16px",
                textTransform: "none",
                bgcolor: "#000000",
                borderRadius: "24px",
            
                px: "20px",
                py: '2px',
                fontWeight: "300",
                "&:hover": {
                  bgcolor: "#222",
                },
                "@media (max-width: 899px)": {
                  display: "none",
                },
              }}
            >
              Sign in
            </Button>
          </Grid>
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={toggleMobileDrawer}
            sx={{ display: { xs: "flex", md: "none", color: "#000", marginRight: '0px' } }}
          >
            <MenuIcon />
          </IconButton>

        </Toolbar>
      </AppBar>

      <Drawer
        anchor="top"
        open={isMobileDrawerOpen}
        onClose={toggleMobileDrawer}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        sx={{
          display: { xs: "flex", md: "none" },
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent", // Set background to transparent
            backdropFilter: "blur(16px)",
          }
        }}
      >
        <Box
          sx={{
            background: "rgba(255, 255, 255, 1)",
            backdropFilter: "blur(16px)",
          }}
        >
          <List sx={{ paddingTop: '122px', paddingBottom: '30px' }}>            
            <Grid>
              <Button
                href="/"
                sx={{
                  color: "#81859f",
                  fontSize: "16px",
                  fontWeight: '300',
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "8px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                <img src="/icons/home_icon.png" style={{ width: '22px', marginRight: '6px' }} />
                Home
              </Button>
            </Grid>

            <Grid>
              <Button
                href=""
                sx={{
                  color: "#81859f",
                  fontSize: "16px",
                  fontWeight: '300',
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "8px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
                aria-controls="products-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                endIcon={<ArrowDropDownIcon />}
              >
                <img src="/icons/billing_icon.png" style={{ width: '22px', marginRight: '6px' }} /> Features
              </Button>

              <Menu
                id="products-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "products-menu",
                }}
                PaperProps={{ style: { borderRadius: '14px' } }}
                sx={{ zIndex: '999999' }}

              >
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontWeight: "300",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Link
                    href=""
                    style={{ color: "#000", textDecoration: "none", display: 'inherit' }}
                  >
                    <img src="/icons/user_icon.png" style={{ height: '18px', marginRight: '6px' }} /> Features Name
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontWeight: "300",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Link
                    href=""
                    style={{ color: "#000", textDecoration: "none", display: 'inherit' }}
                  >
                    <img src="/icons/user_icon.png" style={{ height: '18px', marginRight: '6px' }} /> Features Name
                  </Link>
                </MenuItem>

              </Menu>
            </Grid>

            <Grid>
              <Button
                href="/pricing"
                sx={{
                  color: "#81859f",
                  fontSize: "16px",
                  fontWeight: '300',
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "8px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                <img src="/icons/invoice_icon.png" style={{ width: '22px', marginRight: '6px' }} /> Pricing
              </Button>
            </Grid>

            <Grid>
              <Button
                href="/SignIn"
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: '300',
                  textTransform: "none",
                  bgcolor: "#13B705",
                  borderRadius: "24px",
                  px: "16px",
                  mt: "10px",
                  ml: "40px",
                  "&:hover": {
                    bgcolor: "#000",
                    color: "#fff",
                  },
                }}
              >
                <img src="/icons/user_icon_white.png" style={{ width: '22px', marginRight: '6px' }} /> SignIn
              </Button>
            </Grid>
          </List>
        </Box>
      </Drawer>

    </Box>
  );
};

export default Navbar;
