import React, { ReactNode, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import Navigation from "../navigation/navigation";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
type RouteGuardProps = {
  children: ReactNode;
};
interface MenuItem {
  Children: any;
  Url: string;
  MenuID: string;
  Name: string;
}
interface ChildMenuProps {
  UserTypeID: Number;
  MenuID: string;
  Name: string;
  url: string;
  ImagePath: string;
}
const AuthInnerNavbar = ({ children }: RouteGuardProps) => {
  const [subMenuOpen, setSubMenuOpen] = useState<{ [key: number]: boolean }>(
    {}
  );
  const sessionData = JSON.parse(
    sessionStorage.getItem("MenuList") || "null"
  ) || { Data: [] };
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);


  const handleMenu = (menuUrl: string) => {
    navigate(menuUrl);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Navigation />
      <CssBaseline />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          mt: "66px",
          maxHeight: "calc(100vh - 66px)",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AuthInnerNavbar;
