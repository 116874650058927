import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StatusListProps = {
  StatusID: string;
  StatusType: string;
};

type UserTypeProps = {
  UserTypeID: number;
  UserType: string;
};

type UserInfo = {
  userName: string;
  jwtToken: string;
  userID: string;
  UserTypeID: number;
  ProfileImagePath: string;
  PersonName: string;
  Role: string;
};

type InitialStateProps = {
  statusTypeList: StatusListProps[];
  userTypeList: UserTypeProps[];

  UserTypeID: number;

  userInfo: UserInfo;
};

const initialState: InitialStateProps = {
  statusTypeList: [],
  userTypeList: [],

  UserTypeID: 0,
  userInfo: {
    userName: '',
    jwtToken: '',
    userID: '',
    UserTypeID: 0,
    ProfileImagePath: '',
    PersonName: '',
    Role: '',
  },
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    add_user_info: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    add_user_type_list: (state, action: PayloadAction<UserTypeProps[]>) => {
      state.userTypeList = action.payload;
    },

    add_status_type_list: (state, action: PayloadAction<StatusListProps[]>) => {
      state.statusTypeList = action.payload;
    },

    update_user_type_id: (state, action: PayloadAction<number>) => {
      state.UserTypeID = action.payload;
    },

    clear_user_info: (state) => {
      state.userInfo = {
        userName: '',
        jwtToken: '',
        userID: '',
        UserTypeID: 0,
        ProfileImagePath: '',
        PersonName: '',
        Role: '',
      };
    },
  },
});

export default userSlice.reducer;
export const {
  add_user_info,
  add_user_type_list,
  update_user_type_id,
  clear_user_info,
} = userSlice.actions;
