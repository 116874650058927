import React, { useEffect, useState } from 'react'
import {
    SelectBranch as SELECT_BRANCH,
  } from "src/components/api/register_branch/registerBranch";
  import { LOGOUT } from 'src/components/helper/constants';
import { ToastError } from 'src/components/uiElements/toast';

const UseCompanyProfileMenu = () => {
    const [selectBranchList, SetSelectBranchList] = useState([]);

    useEffect(() => {
        SelectBranchList();
      }, []);
    const SelectBranchList = async () => {
        SetSelectBranchList([]);
        const response = await SELECT_BRANCH();
        if (!response?.isAuthorized) LOGOUT();
        if (!response?.status) {
          let failResult = JSON.parse(response?.data!);
          ToastError(failResult.Message);
          return;
        }
        var data = JSON.parse(response?.data!);
        SetSelectBranchList(data);
      };
  return {
    selectBranchList,
  }
};

export default UseCompanyProfileMenu;